.slick-slide > div {
    margin: 0 10px;
  }
  
  .slick-dots li.slick-active button:before {
    color:orange !important;
  }

  .slick-track{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:0px;
    width: fit-content
    /* border: solid 2px; */
  }
  .main_review_card {
    display: flex;
    width: 100%;
    justify-content: center;


  }
  .slick-slider{
    /* width: 900px; */
    /* border: solid 2px; */
  }
  .review_card{
    border-radius: 7px;
    box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.277);

  }
 .real_card{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* width: 500px; */
 }


  .review_card{
    margin: 10px 0px;
    /* width: 200px; */
  }


  @media (max-width: 768px) {

    .main_review_section{
      margin-top: 570px;

    }
  }

.slick-slide > div {
    margin: 0 10px;
  }
  
  .slick-dots li.slick-active button:before {
    color:orange !important;
  }

  .review_static_card{
    border-radius: 7px;
    /* box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.277); */

  }
 
 .real_card{
    display: flex;
    flex-wrap: wrap;
    /* width: 500px; */
 }
.review_card_admin{
  /* border: solid 2px; */
  height: 10rem;
}
.review_card{
  /* border: solid 2px; */
  flex-wrap: wrap;
}

.new{
  /* border:solid; */
  flex-wrap: wrap;
}
  .review_static_card{
    margin: 10px 0px;
    width: 200px;
    /* border: solid 4px purple; */
  }

  @media screen and (min-width: 768px) {
.review_card{
  width: fit-content;

}

.inner_review_card{
  /* height: fit-content; */
  width: fit-content;
}
.width_to_change{
  width: 360px;
  height: 20rem;
}

  }
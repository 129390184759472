body {
    font-family: 'Poppins';
}

.hover_card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    width: 100%;
    /* place-items: center; */
    /* margin-inline: 1.5rem; */
    /* padding-block: 5rem; */
}

.card__container {
    display: flex;
    justify-content: center;
    align-items: center;
    display: grid;
    row-gap: 3.5rem;
}

.card__data {
    border: none;
}

.card__article {
    position: relative;
    overflow: hidden;
    border-radius: 1.5rem;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.548);


}

.card__img {
    width: 200px;
    filter: brightness(1);
    border: solid 1px;
    border-radius: 1.5rem;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.548);
    height: 300px;
}

.card__img:hover {
    filter: brightness(1);
}

.card__data {
    /* width: 100px; */
    background-color: rgb(255, 255, 255);

    /* background-color: var(--container-color); */
    padding: 1.5rem 2rem;
    box-shadow: 0 8px 24px hsla(0, 0%, 0%, .15);
    border-radius: 1rem;
    position: absolute;
    bottom: -9rem;
    left: 0;
    right: 0;
    margin-inline: auto;
    opacity: 0;
    transition: opacity 1s 1s;
}

.card__description {
    display: block;
    font-size: var(--small-font-size);
    margin-bottom: .25rem;
}

.card__title {
    font-size: 15px;
    font-weight: 500;
    color: var(--title-color);
    margin-bottom: .75rem;
}

.card__button {
    text-decoration: none;
    font-size: var(--small-font-size);
    font-weight: 500;
    color: var(--first-color);
}

.card__button:hover {
    text-decoration: underline;
}

/* Naming animations in hover */
.card__article:hover .card__data {
    animation: show-data 1s forwards;
    opacity: 1;
    transition: opacity .3s;
}

.card__article:hover {
    animation: remove-overflow 2s forwards;
}

.card__article:not(:hover) {
    animation: show-overflow 2s forwards;
}

.card__article:not(:hover) .card__data {
    animation: remove-data 1s forwards;
}

/* Card animation */
@keyframes show-data {
    50% {
        transform: translateY(-10rem);
    }

    100% {
        transform: translateY(-7rem);
    }
}

@keyframes remove-overflow {
    to {
        overflow: initial;
    }
}

@keyframes remove-data {
    0% {
        transform: translateY(-7rem);
    }

    50% {
        transform: translateY(-10rem);
    }

    100% {
        transform: translateY(.5rem);
    }
}

@keyframes show-overflow {
    0% {
        overflow: initial;
        pointer-events: none;
    }

    50% {
        overflow: hidden;
    }
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 340px) {


    .card__data {
        width: 250px;
        padding: 1rem;
    }
}

/* For medium devices */
@media screen and (min-width: 768px) {
    .card__container {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.5rem;
    }
}

/* For large devices */
@media screen and (min-width: 1120px) {


    .card__container {
        grid-template-columns: repeat(3, 1fr);
    }

    .card__img {
        width: 348px;
    }

    .card__data {
        width: 316px;
        padding-inline: 2.5rem;
    }
}

.category_main {
    font-family: 'Poppins', 'sans-serif';
    /* border: solid 1px; */
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.284);
    width: 18rem;
    height: 200px;
    background-color: rgb(231, 238, 172);
    border-radius: 15px;
    transition: all 0.4s;
    /* Reduced the transition duration */
}

.category_main img {
    width: 12rem;
    transition: transform 0.5s;
    /* Transition effect for the image */
}

.category_main img:hover {
    transform: translateY(-10px);
    cursor: pointer;

}

.category_head {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.category_head h3 {
    font-weight: 700;
}

.category_head p {
    font-size: 15px;
    color: grey;
}

.main_content {
    width: 100%;
    margin: 0px 15px;
    display: flex;
    justify-content: space-between;
    /* border: solid 1px; */
    margin-top: 25px;
    align-items: center;
    /* margin-left: 10px; */
}

.main_content {
    border: solid 1px;
}

.img_content {
    width: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_text h5 {
    font-weight: 900;
}

.category_list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 25px;
}

.main_text {
    display: flex;
    flex-direction: column;
    gap: 25px;
    /* align-items: center; */
}

.main_content img {
    /* border: solid 1px; */
    width: 10rem;
}

@media screen and (max-width: 767px) {
    .card__data {
        position: static;
        opacity: 1;
        transition: none;
        transform: none;
    }

    .card__article:hover .card__data {
        animation: none;
        opacity: 1;
    }
    .card__article{
        margin: 0px 10px;
    }

    .img_content {
        width: 100%;
    }

    .card__img {
        width: 100%;
    }

    .card__article:not(:hover) {
        animation: none;
        overflow: initial;
    }
}
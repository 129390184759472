


#main_img{
    width:26rem;
    height:30rem;
}
@media (max-width: 768px) {
    .elementor-widget-wrap img{
        display: none;
        }
        .elementor-container{
            display: flex;
            justify-content: center;
            align-items: center;
        }
}

@media (max-width:1506px){

    /* .elementor-widget-wrap img{
        width:90rem;
        height:40rem;
        }
         */
         #main_img{
            width: 47rem;
         }
    .elementor-container{
        justify-content: center;
        align-items: center;
    }
}
.cont-404 img{
    width: 50rem;
}
.cont-404{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 768px) {
    .cont-404 img{
        width: 30rem;
    }
}
.card {
    /* box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.208); */
}


.product_cards {
    width: 100%;
    /* margin-top: 10px; */
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    /* margin-bottom: 30px; */
    overflow: hidden;
}
.category_head{
    margin-bottom: 0;
}

.star-rating {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline-block;
}

/* Define styles for smaller screens */
@media (max-width: 768px) {
    .card-body p {
        font-size: 16px;
        /* Adjust as needed */
    }

    .data h5 {
        font-size: 16px;
        /* Adjust as needed */
    }
    .main_product_img{
        width: 10rem;
    }
    .main_products_list{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.star-rating li {
    display: inline-block;
    margin-right: 5px;
}

.star-rating li i {
    font-size: 20px;
    color: gray;
    transition: color 0.3s ease;
}

.star-rating li i:hover,
.star-rating li i.active {
    color: gold;
    /* or any other color for active/filled stars */
}


.product_img {
    /* border: solid 1px; */
    width: 30rem;
    height: 300px;

}

.product_img img {
    height: 100%;

}

.img_content {
    position: relative;
    bottom: 100px;
    right: 30%;
    transition: ease-in;
    /* visibility: hidden; */
    /* z-index: -1; */
}


.card {
    cursor: pointer;
    transition: transform 0.5s;
}

.card img {
    max-width: 100%;
    /* Ensure the image doesn't exceed the card width */
    height: auto;
    /* Maintain aspect ratio */
}


.card:hover {
    transform: translateY(-10px);
}

.card-body p {
    font-size: 18px;
}

.card-body h5:hover {
    color: rgb(41, 173, 41);
}

.card-body {
    padding: 10px;
    /* Adjust padding as needed */
}

.card-title {
    font-size: 1.2rem;
    /* Adjust font size */
}

.card-text {
    font-size: 1rem;
    /* Adjust font size */
}


.product_modal_img img{
    width: 10rem;
}

.apne_cards {
    /* border: solid 2px; */
    width: 100%;
    display: flex;
    height: fit-content;
    gap: 30px
}

.card_container {
    /* border:solid 2px; */
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    height: 80rem;
    margin-top: 0;
    align-items: center;
    /* height: 24.1rem; */
    border-radius: 10px;
    background-color: white;
    /* box-shadow: 0px 2px 4px rgba(47, 46, 46, 0.259); */
}

.actual_cards {
    height: 28rem;
    width: 20rem;
    border-radius: 10px;

    /* box-shadow: 4px 4px 4px 4px rgba(47, 46, 46, 0.259); */
    /* border: solid 2px; */

}


.card_container img {
    width: 100%;
    height: 14rem;
    border-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    /* object-fit: cover; */

}

.actual_cards img {
    height: 18rem;
    object-fit: contain;
}

.card_container button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.data {
    margin-top: 10px;
    align-items: center;
    text-align: center;
}

.data h5 {
    font-weight: 700;
}

.data h3 {
    text-align: center;
}

.data button {
    width: 100%;
}

/* Add these styles to your product.css file */

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    /* Horizontally center */
    align-items: center;
    /* Vertically center */
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width: 70%;
    width: 100%;
    /* Adjust max-width as needed */
}

.main_product_img img {
    width: 15rem;
    object-fit: contain;
}
.main_product_img {
    width: fit-content;
}
.main_products_content{
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}



@media (max-width: 768px) {
    .modal {
        margin-top: 0;
    }

    .modal-content {
        max-width: fit-content;
    }

    .main_product_img{
        /* border: solid 2px; */
        width:100%;
    }
    .main_product_img img {
        width: 100%;
        /* background-color: transparent; */
        object-fit: fill;
        height:500px;
    }

    .product_content h2 {
        font-size: 18px;
        /* Adjust styles for product content */
    }

    .product_content h6 {
        font-size: 14px;
        /* Adjust styles for product content */
    }

    .product_content h5 {
        font-size: 15px;
        /* Adjust styles for product content */
    }

    .card_container{
        height: 250rem;
    }
   
    .counter {
        width: 100px;
    }
    

    .counter button {
        /* width: 200px; */
    }
   

    #total_price_modal {
        width: 84px;
        font-size: 14px;
    }

    .modal button {
        margin-top: 0;
    }

    .main_product_img img{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }


}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.product_detail {
    display: flex;
    gap:10px;
    justify-content: center;
    align-items: center;
}

.product_img img {

    border-radius: 10px;
}


@media (max-width: 768px) {
    .card {
        width: 100%;
        /* Adjust as needed */
    }
}
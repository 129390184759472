.review_stars{
    border: solid 2px;
}

.add_review_container{
    border-radius: 20px;
    width: fit-content;
    background-color: rgba(22, 179, 22, 0.618);
    padding: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.19);
}
.main_review_container{
    display: flex;
    justify-content: center;
}
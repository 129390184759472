.order-confirmed-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 90px;
    align-items: center;
    /* border:Solid 2px black; */
    width: 100%;
}




  .order-confirmed-container {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s ease-in, transform 0.5s ease-in;
  }
  
  .order-confirmed-container.show-animation {
    opacity: 1;
    transform: translateY(0);
  }
  .confirmation-text{
    text-align: center;
  }
  .confirmation-text h2{
    font-weight: 600;
  }
  
  
#table_img img{
    width: 8rem;
    height: 5rem;
    object-fit:contain;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.419);
    border-radius: 10px;
}
#table_img{
    display: flex;
    margin-top: 3px;
    justify-content: center;
    align-items: center;
}

#stockCount{
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.378);
}

.inventory-modal-content{
    width: 300px;
    border: solid 2px;
}
.inventory-modal-body{
    border: solid 8px;
}
.inventory_table{
    margin-top:10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.table-container {
    overflow-x: auto;
  }
  

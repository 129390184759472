.checkout_header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.second_header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    /* border: solid 1px; */
    justify-content: center;
}

.second_header p {
    margin-top: 17px;
}

.checkout_main {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

.left_content {
    width: 60%;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.296);
    border-radius: 10px;

}

.left_content .accordion {
    width: 100%;
}

.summary_table {
    border: solid 2px;
    width: 100%;
}

.right_content {
    height: fit-content;
    border: 2px dashed rgba(128, 128, 128, 0.401);
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.296);
}

.content {
    margin: 20px;
}

.notice p {
    font-size: 12px;
}

.notice {
    margin-top: 100px;
    text-align: center;
}

@media (max-width: 768px) {


    .checkout_main {
        gap: 10px;
    }

    .left_content {
        width: 100%;
    }

    .right_content {
        width: 100%;
    }
    .content{
        margin:0
    }
    .content h4{
        margin:8px 10px;
    }
    #img_summary img{
        display: none;
    }
    table tbody tr td{
        font-size:8px;
    }

   
    

}
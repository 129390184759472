.carousel-inner{
    height: 500px;
    margin-top: 0;
}
.carousel-inner img{
    height: 100%;
}

.add_img_btn{
    margin-top: 20px;
}
.delete_btn{
    /* margin-top: 180px; */
    /* border: solid 50px; */
    /* margin-bottom: 100px; */
}
.carousel-inner{
    height: 500px;
}
#myCarousel{
    height: 500px;
    /* border: solid 2px; */
}

.top_hero_buttons{
    display: flex;
    justify-content: center;
    gap:20px;
align-items: center;
width: 95%;
}

@media (max-width: 768px) {
    .add_img_btn{
        margin-top: 20px;
    }
    .carousel-inner{
        height: 350px;
    }
    #myCarousel{
        height: 180px;
        /* border: solid 2px; */
    }
}
.add_product_container {
    border-radius: 20px;
    background-color: rgba(22, 179, 22, 0.618);
    padding: 20px;
    box-shadow: 4px 0px 14px 0px rgba(0, 0, 0, 0.19);
}

.upload-container {
    padding-top: 50px;
}

.upload-square {
    border: 2px dotted #000;
    height: 200px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 10px;
}

.upload-icon {
    font-size: 48px;
    margin-bottom: 10px;
}

.upload-text {
    font-size: 16px;
}

.upload_img {
    flex-wrap: wrap;
}

/* Styles for the upload square */
.upload-square {
    position: relative;
    width: 200px;
    /* Adjust the width and height as needed */
    height: 200px;
    /* Adjust the width and height as needed */
    border: 2px dashed #ccc;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.378);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /* Prevents the image from overflowing the square */
}

.uploaded_img img {
    width: 30%;
    object-fit: cover;
    border-radius: 5px;
}
.uploaded_square{
    /* height: 200px; */
    height: fit-content;
    width: 500px;
}
.uploaded_img {
    top: 0;
    border: 2px dashed #ccc;
    margin: 5px;
    padding: 10px;
    margin-top: 5px;
    background-color: white;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.378);
    border-radius: 10px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    /* border: solid 2px; */
    gap: 12px;
    width: 100%;
    flex-wrap: wrap;

}
.uploaded_img img{
    width: 100px;
}
.uploaded-image {
    position: absolute;
    top: 0;
    left: 0;
    /* width: 100%; */
    width: 100%;
    /* Ensures the image fills the square */
    height: 100%;
    /* Ensures the image fills the square */
    object-fit: cover;
    /* Maintains the aspect ratio and covers the entire square */
}

.cancel-icon{

    position: relative;
    bottom: 84px;
    left: 90px;

}

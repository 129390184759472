/* .cart_container{
    display: flex;
    gap:790px;
} */
.custom_table{
/* border: solid 2px; */
width: 100%;
}
.main_cart_data{
    margin-top: 50px;
    width: 100%;
}
.first_content_header h3{
font-weight: 600;
}
tbody tr td{
/* border: solid 2px; */
margin-top: 50px;
}

.price_content{
    font-weight: 500;
}

@media (max-width: 768px) {
    table tr th{
        font-size: 10px;
    }
    table tr td{
        font-size: 10px;
    }
    table tbody tr td button{
        font-size: 10px;
        width: fit-content;
    }
    .first_content_header h3{
        font-size: 18px;
        width: fit-content;
    }
    .custom_table img{
        width: 80px;
    }

}


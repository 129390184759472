.outer_stats_card{
    display:flex;
    justify-content: center;
    gap:5rem;
    width:100%;
    margin-bottom: 2rem;
    flex-wrap: wrap;
}

.stats_card{
    background-color: #ffff;
    box-shadow: 5px 5px 20px rgb(0,0,0,0.4);
    border: none;
    border-radius: 20px;
    padding: 2rem;
    min-width: 15rem;
    width: fit-content;
}
.stats_card p{
    font-size: 26px;
}
.stats_head{
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
    gap:3rem;

}
.stats_head h3{
    font-weight: 600;
}
.stats_card h4{
    font-weight: 600;
}

.pie-bg{
    background-color: #ffff;
    border: none;
    box-shadow: 4px 4px 20px rgb(0, 0, 0, 0.2);
    padding: 0rem;
    border-radius: 1rem;
}

.chart-container{
    text-align:center;
    width:40%;
}

.outer_chart_container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:2rem;
    flex-wrap: wrap;
    width: 100%;
}
.main_charts_cont{
    display: flex;
    justify-content: center;
    gap:5rem;
    flex-wrap: wrap;
    margin-top:2rem;
}

.outer_main_charts{
    display: flex;
    flex-direction: column;
    background-color: #ffff;
    box-shadow: 4px 4px 20px rgb(0, 0, 0, 0.4);
    border-radius: 2rem;
    padding: 2rem;
    width: 100%;
    flex-wrap: wrap;
}

@media (max-width: 768px) {

   .chart-container{
    width: 100%;
   }

   @media screen and (max-width: 576px) {
    .chart-container{
        width: 100%;
       }
   }
 
  
}
*,
*::after,
*::before{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root{
    --blue-color: #0c2f54;
    --dark-color: #535b61;
    --white-color: #fff;
}

ul{
    list-style-type: none;
}
ul li{
    margin: 2px 0;
}

/* text colors */
.text-dark{
    color: var(--dark-color);
}
.text-blue{
    color: var(--blue-color);
}
.text-end{
    text-align: right;
}
.text-center{
    text-align: center;
}
.text-start{
    text-align: left;
}
.text-bold{
    font-weight: 700;
}
/* hr line */
.hr{
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
}
/* border-bottom */
.border-bottom{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

body{
    font-family: 'Poppins', sans-serif;
    color: var(--dark-color);
    font-size: 14px;
}
.invoice-wrapper{
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    padding-bottom: 20px;
}
.invoice{
    max-width: 850px;
    margin-right: auto;
    margin-left: auto;
    background-color: var(--white-color);
    padding: 70px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    min-height: 920px;
}
.invoice-head-top-left img{
    width: 130px;
}
.invoice-head-top-right h3{
    font-weight: 500;
    font-size: 27px;
    color: var(--blue-color);
}
.invoice-head-middle, .invoice-head-bottom{
    padding: 16px 0;
}
.invoice-body{
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
}
.invoice-body table{
    border-collapse: collapse;
    border-radius: 4px;
    width: 100%;
}
.invoice-body table td, .invoice-body table th{
    padding: 12px;
}
.invoice-body table tr{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.invoice-body table thead{
    background-color: rgba(0, 0, 0, 0.02);
}
.invoice-body-info-item{
    display: grid;
    grid-template-columns: 80% 20%;
}
.invoice-body-info-item .info-item-td{
    padding: 12px;
    background-color: rgba(0, 0, 0, 0.02);
}
.invoice-foot{
    padding: 30px 0;
}
.invoice-foot p{
    font-size: 12px;
}
.invoice-btns{
    color: white;
    margin-top: 20px;
    display: flex;
    gap:20px;
    justify-content: center;
}
.invoice-btns button{
    color: white;
}
.invoice-btn{
    padding: 3px 9px;
    color: var(--dark-color);
    font-family: inherit;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.invoice-head-top, .invoice-head-middle, .invoice-head-bottom{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 10px;
}

@media screen and (max-width: 992px){
    .invoice{
        padding: 40px;
    }
}

@media screen and (max-width: 765px){
    .invoice-head-top, .invoice-head-middle, .invoice-head-bottom{
        grid-template-columns: repeat(1, 1fr);
    }
    .invoice-head-bottom-right{
        margin-top: 12px;
        margin-bottom: 12px;
    }
    .invoice *{
        text-align: left;
    }
    .invoice{
        padding: 28px;
    }
}

.info-item-td{
  font-size: 10px;
}
.overflow-view{
    overflow-x: scroll;
}
.invoice-body{
    min-width: 600px;
}

@media print{
    .print-area{
        visibility: visible;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
    }

    .overflow-view{
        overflow-x: hidden;
    }

    .invoice-btns{
        display: none;
    }
}

.invoice-wrapper {
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .invoice {
    max-width: 850px;
    margin-right: auto;
    margin-left: auto;
    background-color: var(--white-color);
    padding: 70px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    min-height: 920px;
  }
  
  .invoice-head-top-left img {
    max-width: 100%;
    height: auto;
  }
  
  .invoice-head-middle,
  .invoice-head-bottom {
    padding: 16px 0;
  }
  
  .invoice-body {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    min-width: 600px; /* Adjust as needed */
  }
  
  .invoice-body table {
    border-collapse: collapse;
    border-radius: 4px;
    width: 100%;
  }
  
  .invoice-body table td,
  .invoice-body table th {
    padding: 12px;
  }
  
  .invoice-foot {
    padding: 30px 0;
  }
  
  .invoice-foot p {
    font-size: 12px;
  }
  
  .invoice-btns {
    color: white;
    margin-top: 20px;
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  
  .invoice-btns button {
    color: white;
  }
  
  .invoice-btn {
    padding: 3px 9px;
    color: var(--dark-color);
    font-family: inherit;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .invoice-wrapper {
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .invoice {
    max-width: 850px;
    margin-right: auto;
    margin-left: auto;
    background-color: var(--white-color);
    padding: 70px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    min-height: 920px;
  }
  
  .invoice-head-top-left img {
    max-width: 100%;
    height: auto;
  }
  
  .invoice-head-middle,
  .invoice-head-bottom {
    padding: 16px 0;
  }
  
  .invoice-body {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    min-width: 600px; /* Adjust as needed */
  }
  
  .invoice-body table {
    border-collapse: collapse;
    border-radius: 4px;
    width: 100%;
  }
  
  .invoice-body table td,
  .invoice-body table th {
    padding: 12px;
  }
  
  .invoice-foot {
    padding: 30px 0;
  }
  
  .invoice-foot p {
    font-size: 12px;
  }
  
  .invoice-btns {
    color: white;
    margin-top: 20px;
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  
  .invoice-btns button {
    color: white;
  }
  
  .invoice-btn {
    padding: 3px 9px;
    color: var(--dark-color);
    font-family: inherit;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
    

  @media print {
    /* Hide the navbar during printing */
    .navbar {
      display: none !important;
    }
  }
  @media print {
    .print-table {
        width: 50%; /* Adjust the width as needed */
    }
}


  /* Adjustments for smaller screens */
@media screen and (max-width: 576px) {
  .invoice {
      padding: 28px; /* Reduce padding for smaller screens */
  }

  .invoice-head-top,
  .invoice-head-middle,
  .invoice-head-bottom {
      grid-template-columns: repeat(1, 1fr); /* Change grid layout to single column */
  }

  .invoice-head-bottom-right {
      margin-top: 12px;
      margin-bottom: 12px;
  }

  .invoice * {
      text-align: left; /* Adjust text alignment for readability */
  }

  .invoice-body {
      min-width: auto; /* Allow table to adjust width based on content */
      overflow-x: auto; /* Enable horizontal scrolling for the table */
  }
}

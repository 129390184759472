.icon-container {
  display: inline-flex;
  align-items: center;
}

.icon {
  margin-right: 8px;
  /* Adjust margin as needed */
}

.green-circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: green;
  margin-left: 5px;
  /* Adjust margin as needed */
}

.icon {
  /* border: solid 1px; */
  width: 100%;
  height: 50px;
  color: green;
}

.order_content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.order_content p {
  margin-top: 12px;
  font-size: 20px;
  font-weight: 600;
}

.dashboard {
  display: flex;
  gap: 50px;
  width: 60rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  /* border: solid 2px; */
}

.card {
  width: 15rem;
}

.nav-pills .nav-link.active {
  background-color: rgb(14, 67, 14);
}

.nav-item {
  list-style: none;
  ;
}

.sidebar {
  position: sticky;
  top: 0;
  height: calc(100vh - 60px);
  /* Adjust height based on your Navbar height */
  overflow-y: auto;
  /* Enable vertical scrolling if the content exceeds the height */
}

.ordered_card {
  /* width: 100%; */
  width: 40rem;
  /* border: solid 2px; */
}

.outer_order_card {
  display: flex;
  width: 60rem;
  justify-content: center;
  align-items: center;
  /* border: solid 2px green; */
}

.order_img img{
  width: 20rem;
  height: 12rem;
}
@media (max-width: 768px) {

  .sidebar {
    display: none;
  }

  .dashboard {
    width: 90%;
    gap: 10px;
    display: flex;
    /* border: solid 2px; */
    justify-content: center;
    align-items: center;
  }
  .content{
    width: 100%;
  }
  .order_top_card{
    width: 100%;
    /* border: solid 2px; */
  }
  .outer_order_card{
    height: fit-content;
    width: 100%;
    /* flex-wrap: nowrap; */
  }
  .order_img{
    margin-top:10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .order_img img{

    width: 8rem;
    height: 8rem;
    /* border: solid 4px; */
  }
  .order_card{
    /* border: solid 2px; */
    width: 50%;
  }
  .dashboard_card{
    width: 100%;
  }

}
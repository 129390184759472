.product_card {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap;       idhar responsive ke time wrap krna hai  */
    height: 300px;
    gap: 80px;
}

.product_card img {
    width: 25rem;
    border-radius: 20px;
    height: 540px;
    /* box-shadow: 4px 14px 0px 4px rgba(0, 0, 0, 0.327); */
    /* backdrop-filter: 10px 10px 5px lightblue; */
}

.product_img {
    /* border: solid 1px; */
    width: 20rem !important;
    /* height: 5rem; */


}
.testimonials{
    margin-top: 120px;
}

.product_details {
    width: 100%;
}

.main_img {
    /* border: solid 2px red; */
    border-radius: 10px;
    width: 800px;
    height: 500px;
    box-shadow: 4px 4px 4px 4px 4px rgba(0, 0, 0, 0.536);
}

.sub_info p {
    font-family: 12px;
    color: grey;
    /* width: 50%; */
    text-align: justify;
}

#price {
    font-size: 25px;
}

.product_details h2 {
    font-weight: 600;
}

.buttons {
    display: flex;
    gap: 3px;
    margin: 10px 0px;

}


.product_thumbnails {
    /* border: solid 1px; */
    width: fit-content;
    border-radius: 10px;
    height: fit-content;

}

.product_thumbnails img {
    height: fit-content;
    width: 8rem;
    border-radius: 0;
    /* margin: 5px 5px; */
}

.product_thumbnails img:hover {
    cursor: pointer;
}
.form_control_product{
    margin-top: 10px;
}

/* product_thumbnails */

@media (max-width: 768px) {

    .product_thumbnails {
        display: none;
    }

    .product_card {
        flex-wrap: wrap;
        justify-content: center;
        gap: 0;
        align-items: center;
    }

 
   .buttons{
    /* border: solid 4px; */
   }

   .product_img {
    /* Adjust the width for smaller screens */
    width: 15rem !important;
    /* Optionally adjust the height */
    height: auto;
}
    .product_details {
        margin-top: 230px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .form_control_product{
        margin-top: 10px;
    }
    .product_details h2 {
        font-size: 20px;
    }
    .sub_info{
        padding: 10px;
        
    }
    .sub_info p{
        font-size: 12px;
    }
    .testimonials{
        margin-top: 50rem;
    }
  
}